import { type LogsEvent, datadogLogs } from "@datadog/browser-logs";
import { DATADOG_SITE } from "./constants";
import {
  abortError,
  adBlockerErrorAmplitude,
  chromeExtensionError,
  resizeObserverError,
  trackerBlockerError,
  typeError,
  undefinedScriptError,
} from "./ignoreFilters/ignoreFilters";

// eslint-disable-next-line max-statements
export function beforeSend(event: LogsEvent) {
  const { error, origin } = event;
  // Ignore logs that are not errors or are from the legacy code
  if (!error) {
    return false;
  }

  if (origin === "console") {
    return false;
  }

  if (resizeObserverError(event)) {
    return false;
  }

  if (typeError(event)) {
    return false;
  }

  if (abortError(event)) {
    return false;
  }

  if (chromeExtensionError(event)) {
    return false;
  }

  if (undefinedScriptError(event)) {
    return false;
  }

  if (trackerBlockerError(event)) {
    return false;
  }

  if (adBlockerErrorAmplitude(event)) {
    return false;
  }

  return true;
}

export function initDatadogLogging() {
  const { clientToken, env, service, version } =
    window._datadogErrorLoggingConfig || {};
  if (!clientToken) {
    return;
  }
  datadogLogs.init({
    clientToken,
    site: DATADOG_SITE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service,
    version,
    env,
    beforeSend,
  });
}
